import React from "react";
import { styled } from "styled-components";

const GridOfImagesContainer = styled.div`
  display: grid;
  grid-column-gap: 20px;

  &.layout-one-image {
    display: block;
  }
  &.layout-two-images {
    grid-template-columns: 1fr 1fr;
  }

  &.layout-three-images {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 900px) {
    &.layout-two-images,
    &.layout-three-images {
      display: block;
      grid-template-columns: unset;
    }
  }
`;

export const GridOfImages = ({ images }) => {
  const determineLayout = (images) => {
    if (images.length === 0 || images.length === 1) {
      return `one-image`;
    } else if (images.length === 2 || images.length >= 4) {
      return `two-images`;
    } else {
      return `three-images`;
    }
  };

  return (
    <GridOfImagesContainer className={`layout-${determineLayout(images)}`}>
      {images}
    </GridOfImagesContainer>
  );
};
