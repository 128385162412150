import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: 'Univers LT Std';
    src: url('/fonts/univers/UniversLTStd-Ex.eot');
    src: url('/fonts/univers/UniversLTStd-Ex.eot?#iefix') format('embedded-opentype'),
        url('/fonts/univers/UniversLTStd-Ex.woff2') format('woff2'),
        url('/fonts/univers/UniversLTStd-Ex.woff') format('woff'),
        url('/fonts/univers/UniversLTStd-Ex.ttf') format('truetype'),
        url('/fonts/univers/UniversLTStd-Ex.svg#UniversLTStd-Ex') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Louize Display';
    src: url('/fonts/louize/LouizeDisplay.eot');
    src: url('/fonts/louize/LouizeDisplay.eot?#iefix') format('embedded-opentype'),
        url('/fonts/louize/LouizeDisplay.woff2') format('woff2'),
        url('/fonts/louize/LouizeDisplay.woff') format('woff'),
        url('/fonts/louize/LouizeDisplay.ttf') format('truetype'),
        url('/fonts/louize/LouizeDisplay.svg#LouizeDisplay') format('svg');
    font-weight: normal;
    font-style: normal;
}

  h1, h2, h3{
    font-family: 'Louize Display', serif;
  }

  /* br {
    display: block;
  } */

  // html{
  //   background-color: #000;
  // }

  body {
    color: #272727;

    font-family: 'Univers LT Std', system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
    font-size: 20px;
    line-height: 1.35;

    text-shadow: 1px 1px 1px rgba(0,0,0,.004);
    text-rendering: optimizeLegibility!important;
    -webkit-font-smoothing: antialiased!important;
    -webkit-tap-highlight-color: transparent;

    -webkit-overflow-scrolling: touch;


    @media (max-width: 767px) {
        font-size: 18px;
        line-height: 24px;
    }
  }

    & a,
    & a:visited {
      text-decoration: none;
      color: inherit;
    }

    & a:focus{
      outline: none;
    }

    & a.active{
      color: #fa3c44;
    }


    h1, h2, h3, h4, h5, h6{
      margin: 0;
      font-weight: normal;
    }

    h1,h2 {
      font-size: 60px;
      line-height: 1.25;

      @media (max-width: 991px) {
        font-size: 40px;
      }

      @media (max-width: 767px) {
        font-size: 26px;
      }
    }

    h3{
      font-size: 30px;
      line-height: 40px;
      letter-spacing: 0;

      @media (max-width: 767px) {
          font-size: 22px;
          line-height: 28px;
      }
    }

     ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }


    button {
      cursor: pointer;
      padding: 0;
      margin: 0;
      -webkit-appearance: none;
      border: 0;
      background-color: transparent;

      &:hover {
        color: #fa3c44;
      }

      &:focus {
        outline: 0;
      }
    }


img {
  width: 100%;
}
`;

export default GlobalStyle;
