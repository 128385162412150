import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Col, Row } from "styled-bootstrap-grid";
import Clock from "react-live-clock";

// Components
import { ProjectNavigation } from "../navigation/project-navigation";

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;

  z-index: 1000;

  width: 100%;
  padding: 0 50px;

  font-family: "Louize Display", serif;
  line-height: 100px;

  background-color: ${(props) => props.color};

  & .nav {
    position: relative;
    z-index: 1001;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
    line-height: 80px;
  }
`;

const SiteTitle = styled.h2`
  transition: 100ms font-size linear;
  font-size: ${(props) => (props.scrollActive === true ? `26px` : `60px`)};

  line-height: 100px;

  @media (max-width: 991px) {
    font-size: 40px;
  }

  @media (max-width: 768px) {
    line-height: 80px;
  }

  @media (max-width: 767px) {
    font-size: 26px;
  }
`;

const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  & li {
    padding-right: 50px;
    font-size: 26px;

    & button {
      color: #fff;
    }

    &:last-of-type {
      padding-right: 0;
    }

    @media (max-width: 991px) {
      &:nth-child(3) {
        padding-right: 0;
      }
    }
  }

  @media (max-width: 767px) {
    position: fixed;
    left: 0;
    top: 76px;

    padding: 0 20px;

    width: 100%;

    background: ${(props) => props.backgroundColor};

    flex-direction: column;

    border-top: 1px solid ${(props) => props.color};
    border-bottom: 1px solid ${(props) => props.color};

    & li {
      width: 100%;
      font-size: 26px;
      padding: 0;
    }
  }
`;

const TimestampContainer = styled.li`
  font-family:
    "Univers LT Std",
    system,
    -apple-system,
    ".SFNSText-Regular",
    "San Francisco",
    "Roboto",
    "Segoe UI",
    "Helvetica Neue",
    "Lucida Grande",
    sans-serif;

  font-size: 20px !important;

  @media (max-width: 991px) {
    display: none;
  }
`;

const MenuToggle = styled.button`
  padding: 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  line-height: 1;

  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);

  & .hamburger-box {
    width: 36px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  & .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;

    transition-property: transform;
    transition-duration: 0.075s;
    transform: ${(props) => (props.active ? "rotate(45deg)" : "none")};
    transition-delay: ${(props) => (props.active ? "0.12s" : "none")};
    transition-timing-function: ${(props) =>
      props.active
        ? "cubic-bezier(0.215, 0.61, 0.355, 1)"
        : "cubic-bezier(0.55, 0.055, 0.675, 0.19)"};
  }

  & .hamburger-inner,
  & .hamburger-inner::before,
  & .hamburger-inner::after {
    width: 36px;
    height: 3px;
    background-color: ${(props) => props.color};
    position: absolute;
  }

  & .hamburger-inner::before,
  & .hamburger-inner::after {
    content: "";
    display: block;
  }

  & .hamburger-inner::before {
    top: ${(props) => (props.active ? "0" : "-10px")};
    opacity: ${(props) => (props.active ? "0" : "1")};
    transition: ${(props) =>
      props.active
        ? "top 0.075s ease, opacity 0.075s 0.12s ease"
        : "top 0.075s 0.12s ease, opacity 0.075s ease"};
  }

  & .hamburger-inner::after {
    bottom: ${(props) => (props.active ? "0" : "-10px")};
    transform: ${(props) => (props.active ? "rotate(-90deg)" : "none")};
    transition: ${(props) =>
      props.active
        ? "transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)"
        : "bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19)"};
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const Header = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(true);
  const [scrollActive, setScrollActive] = useState(false);
  const [isProjectNavigationOpen, setIsProjectNavigationOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const getWindowWidth = () => {
    if (window.innerWidth <= 767) {
      setIsDesktop(false);
    } else {
      setIsDesktop(true);
    }
  };

  const isWindowScrolling = () => {
    let scrollTop = window.scrollY;

    if (scrollTop >= 100) {
      setScrollActive(true);
    } else {
      setScrollActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", getWindowWidth);
    window.addEventListener("scroll", isWindowScrolling);
    getWindowWidth();

    return () => {
      window.removeEventListener("resize", getWindowWidth);
      window.removeEventListener("scroll", isWindowScrolling);
    };
  }, []);

  return (
    <HeaderContainer color={isMenuOpen ? props.backgroundColor : "transparent"}>
      <Row justifyContent="between" className="nav">
        <Col auto>
          <SiteTitle scrollActive={scrollActive}>
            <Link to="/">{props.siteTitle}</Link>
          </SiteTitle>
        </Col>

        {/* {isMenuOpen && !isDesktop && (
          <MenuToggle
            onClick={() => toggleMenu()}
            active={isMenuOpen}
            color={props.fillColor}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </MenuToggle>
        )} */}

        {!isMenuOpen && !isDesktop && !isProjectNavigationOpen && (
          <MenuToggle
            onClick={() => toggleMenu()}
            active={isMenuOpen}
            color={props.fillColor}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </MenuToggle>
        )}

        {isMenuOpen && !isDesktop && !isProjectNavigationOpen && (
          <MenuToggle
            onClick={() => {
              setIsProjectNavigationOpen(false);
            }}
            active={isMenuOpen}
            color={props.fillColor}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </MenuToggle>
        )}

        {!isDesktop && isProjectNavigationOpen && (
          <MenuToggle
            onClick={() => {
              setIsProjectNavigationOpen(false);
            }}
            active={isProjectNavigationOpen}
            color={props.fillColor}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </MenuToggle>
        )}

        {isMenuOpen && !isDesktop && (
          <>
            <Col auto>
              <Navigation
                backgroundColor={props.backgroundColor}
                color={props.fillColor}
              >
                <ul>
                  {props.menuLinks.map((link) => {
                    if (link.name === `Archive` && props.location === `/`) {
                      return (
                        <li key={link.name}>
                          <button
                            onClick={() => {
                              setIsProjectNavigationOpen(
                                !isProjectNavigationOpen
                              );
                              toggleMenu();
                            }}
                          >
                            {link.name}
                            {/* {isProjectNavigationOpen ? `✕` : link.name} */}
                          </button>
                        </li>
                      );
                    } else {
                      return (
                        <li key={link.name}>
                          <Link to={link.link} onClick={() => toggleMenu()}>
                            {link.name}
                          </Link>
                        </li>
                      );
                    }
                  })}
                  <TimestampContainer>
                    <Clock format={"hh:mma"} ticking={true} />
                  </TimestampContainer>
                </ul>
              </Navigation>
            </Col>
          </>
        )}

        {isDesktop && (
          <>
            <MenuToggle
              onClick={() => toggleMenu()}
              active={isMenuOpen}
              color={props.fillColor}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </MenuToggle>

            <Col auto>
              <Navigation>
                <ul>
                  {props.menuLinks.map((link) => {
                    if (link.name === `Archive` && props.location === `/`) {
                      return (
                        <li key={link.name}>
                          <button
                            onClick={() =>
                              setIsProjectNavigationOpen(
                                !isProjectNavigationOpen
                              )
                            }
                          >
                            {isProjectNavigationOpen ? `✕` : link.name}
                          </button>
                        </li>
                      );
                    } else {
                      return (
                        <li key={link.name}>
                          <Link to={link.link} onClick={() => toggleMenu()}>
                            {link.name}
                          </Link>
                        </li>
                      );
                    }
                  })}
                  <TimestampContainer>
                    <Clock format={"hh:mma"} ticking={true} />
                  </TimestampContainer>
                </ul>
              </Navigation>
            </Col>
          </>
        )}
      </Row>

      {isProjectNavigationOpen && (
        <ProjectNavigation
          setIsProjectNavigationOpen={setIsProjectNavigationOpen}
        />
      )}
    </HeaderContainer>
  );
};

export default Header;
