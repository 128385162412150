import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";

const IframeContainer = styled.div`
  position: relative;
  padding-bottom: ${props => props.aspectRatio + `%`};
  margin-bottom: 30px;
  background-color: rgba(15, 15, 15, 0.8);
  height: 0;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Playbutton = styled.div`
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 44px;

  border-color: transparent transparent transparent #fff;
  transition: 100ms opacity ease;
  cursor: pointer;

  // play state
  border-style: solid;
  border-width: 22px 0 22px 40px;

  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 200;

  &:hover {
    opacity: 0.8;
  }
`;

const Poster = styled.div`
  opacity: ${props => (props.visible === true ? 1 : 0)};

  transition: 1000ms opacity ease;

  & img {
    z-index: 100;

    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

const PauseButton = styled.button`
  position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  bottom: 2px;
  left: 10px;

  color: #fff;
  z-index: 2;

  &:hover {
    color: #fff;
  }
`;

const MuteButton = styled.button`
  position: absolute;
  bottom: 2px;
  right: 10px;

  color: #fff;
  z-index: 2;

  &:hover {
    color: #fff;
  }
`;

async function getVimeoThumbnail(vimeoId, width, height) {
  let response = await fetch(
    `https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${vimeoId}&width=${width}&height=${height}&quality=1080`
  );
  let data = await response.json();
  return data;
}

let vimeoThumbnail;
class Iframe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      controls: false,
      volume: 1,
      visible: true,
      thumbnail: "",
      video: null,
      muted: true,
    };
    this.handlePlayPause = this.handlePlayPause.bind(this);
  }

  componentDidMount() {
    this.setState({
      video: this.props.video,
    });

    let video_id = /^.*((youtu.be\/|vimeo.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/,
      match = this.props.embed_url.match(video_id);

    if (this.props.height > this.props.width) {
      vimeoThumbnail = getVimeoThumbnail(match[7], 1080, 1920);
      vimeoThumbnail.then(result => {
        this.setState({
          thumbnail: result.thumbnail_url,
        });
      });
    } else {
      vimeoThumbnail = getVimeoThumbnail(match[7], 1920, 1080);
      vimeoThumbnail.then(result => {
        this.setState({
          thumbnail: result.thumbnail_url,
        });
      });
    }

    const autoplay = this.props.autoplay === true ? true : false;
    const shouldVideoStartMuted = autoplay === true ? true : false;

    this.setState({
      playing: autoplay,
      muted: shouldVideoStartMuted,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isVideoPlaying !== this.props.isVideoPlaying) {
      this.setState({
        playing: false,
        visible: true,
      });
    }
  }

  handlePlayPause = () => {
    this.props.setCurrentVideoPlayerId(this.props.videoId);
    this.setState({
      playing: !this.state.playing,
      visible: !this.state.visible,
    });
  };

  handlePlay = () => {
    this.setState({ playing: true, visible: false });
  };

  handlePause = () => {
    this.setState({ playing: false, visible: true });
  };

  // handlePause = () => {
  //   this.setState({
  //     playing: false,
  //     visible: true,
  //   });
  // };

  handleMuteUnmute = () => {
    this.setState({
      muted: !this.state.muted,
    });
  };

  ref = player => {
    this.player = player;
  };

  render() {
    const { playing, controls, volume, visible, video } = this.state;

    return (
      <IframeContainer
        aspectRatio={(this.props.height / this.props.width) * 100}
      >
        <Poster visible={visible && playing === false}>
          <Playbutton onClick={this.handlePlayPause} />
          <img src={this.state.thumbnail} />
        </Poster>
        {video !== null && (
          <ReactPlayer
            ref={this.ref}
            url={video}
            width="100%"
            height="100%"
            // playing={this.props.isVideoPlaying}
            playing={playing}
            controls={controls}
            volume={volume}
            onPlay={this.handlePlay}
            onPause={this.handlePause}
            muted={this.state.muted}
            loop
          />
        )}
        {this.props.autoplay !== true && (
          <PauseButton onClick={this.handlePlayPause}>Stop</PauseButton>
        )}
        {this.props.doesVideoHaveAudio === true && (
          <MuteButton onClick={this.handleMuteUnmute}>
            {this.state.muted ? `Unmute` : `Mute`}
          </MuteButton>
        )}
      </IframeContainer>
    );
  }
}

export default Iframe;
