import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import { Location } from "@reach/router";

import { BaseCSS, GridThemeProvider, Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

// Styles
import "../styles/normalize.css";
import GlobalStyles from "../styles/globalStyles";

// Components
import Header from "./header";

const Container = styled.div`
  width: 100%;
  padding-right: 50px;
  padding-left: 50px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const SiteContainer = styled.div`
  background-color: ${props => props.bgColor};
  color: ${props => props.fillColor};

  min-height: 100vh;
  transition: 250ms background-color ease;

  padding-bottom: 50px;
`;

const ContentContainer = styled.div`
  width: 100%;

  margin-top: 100px;

  @media (max-width: 768px) {
    margin-top: 60px;
  }
`;

// aboutTextSize;

const UserCreatedTextStyles = createGlobalStyle`
  body {
    font-size: ${props => props.bodyTextSize}px;


    @media (max-width: 767px) {
        font-size: 18px;
        line-height: 24px;
    }
  }
  h1,h2 {
    font-size: ${props => props.headlineTextSize}px;

          @media (max-width: 991px) {
        font-size: 40px;
      }

      @media (max-width: 767px) {
        font-size: 26px;
      }
  }
`;

const gridTheme = {
  breakpoints: {
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 15, // default 15
  },
  col: {
    padding: 15, // default 15
  },
  container: {
    padding: 50, // default 15
    maxWidth: {
      // defaults below
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
};

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        prismicHomepage {
          prismicId
          data {
            background_color
            headline_text_size
            body_text_size
          }
        }
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => {
      const headlineTextSize = data.prismicHomepage.data.headline_text_size;
      const bodyTextSize = data.prismicHomepage.data.body_text_size;

      return (
        <React.Fragment>
          <BaseCSS />
          <GlobalStyles />
          <UserCreatedTextStyles
            headlineTextSize={headlineTextSize}
            bodyTextSize={bodyTextSize}
          />
          <Helmet title={data.site.siteMetadata.title} />
          <Location>
            {({ location }) => {
              return (
                <SiteContainer
                  location={location.pathname}
                  fillColor={
                    location.pathname === "/"
                      ? data.prismicHomepage.data.background_color === true
                        ? "#fff"
                        : "#272727"
                      : "#272727"
                  }
                  backgroundColor={
                    location.pathname === "/"
                      ? data.prismicHomepage.data.background_color === true
                        ? "#000"
                        : "#fff"
                      : "#fff"
                  }
                >
                  <GridThemeProvider gridTheme={gridTheme}>
                    <Container>
                      <Row>
                        <Col col={12}>
                          <Header
                            menuLinks={data.site.siteMetadata.menuLinks}
                            siteTitle={data.site.siteMetadata.title}
                            location={location.pathname}
                            fillColor={
                              location.pathname === "/"
                                ? data.prismicHomepage.data.background_color ===
                                  true
                                  ? "#fff"
                                  : "#272727"
                                : "#272727"
                            }
                            backgroundColor={
                              location.pathname === "/"
                                ? data.prismicHomepage.data.background_color ===
                                  true
                                  ? "#000"
                                  : "#fff"
                                : "#fff"
                            }
                          />
                        </Col>

                        <Col col={12}>
                          <ContentContainer>{children}</ContentContainer>
                        </Col>
                      </Row>
                    </Container>
                  </GridThemeProvider>
                </SiteContainer>
              );
            }}
          </Location>
        </React.Fragment>
      );
    }}
  />
);

export default Layout;
