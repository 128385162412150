exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "homepage": {
      return `/`;
    }

    case "about": {
      return `/about/`;
    }

    case "contact": {
      return `/contact/`;
    }

    case "project": {
      return `/project/${doc.uid}/`;
    }

    default: {
      return `/${doc.uid}/`;
    }
  }
};
