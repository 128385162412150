import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

// Utils
import Iframe from "../utils/iframe";

// Components
import Gallery from "../images/gallery";
import { GridOfImages } from "../images/grid-of-images";

import AOS from "aos";
import "aos/dist/aos.css";

const ProjectNavigationContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1000;
  background-color: rgb(0 0 0 / 90%);
  color: #fff;

  overflow: auto;

  padding: 100px 50px 50px 50px;

  @media (max-width: 768px) {
    padding: 80px 20px;
  }

  & ol {
    position: relative;
    padding: 0;
    margin: 0;

    & li {
      display: grid;
      grid-template-columns: 1fr 200px;
      grid-column-gap: 20px;

      cursor: pointer;

      border-bottom: 1px solid #fff;
      padding: 20px 0;

      @media (max-width: 768px) {
        grid-template-columns: 1fr 100px;
      }

      & .title-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 20px;

        & p {
          font-family:
            "Univers LT Std",
            system,
            -apple-system,
            ".SFNSText-Regular",
            "San Francisco",
            "Roboto",
            "Segoe UI",
            "Helvetica Neue",
            "Lucida Grande",
            sans-serif;
          font-size: 16px;
          line-height: 1.35;

          margin: 0;
        }

        & .mobile-client {
          display: none;

          @media (max-width: 1100px) {
            display: block;
          }
        }

        & .title-container__client {
          @media (max-width: 1100px) {
            display: none;
          }
        }

        @media (max-width: 1100px) {
          grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 850px) {
          grid-template-columns: 1fr;

          & .title-container__role {
            display: none;
          }
        }
      }

      & .content-container {
        width: 200px;
        max-height: 200px;
        overflow: hidden;

        & > div {
          margin-bottom: 0;
        }

        & video,
        & img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: top left;
          display: block;
        }

        @media (max-width: 768px) {
          width: 100px;
          max-height: 100px;
        }
      }
    }
  }
`;

export const ProjectNavigation = ({ setIsProjectNavigationOpen }) => {
  const [currentVideoPlayerId, setCurrentVideoPlayerId] = useState("");

  useEffect(() => {
    AOS.init();
    setTimeout(AOS.refreshHard, 150);
  }, []);

  const data = useStaticQuery(graphql`
    {
      prismicHomepage {
        data {
          projects {
            project {
              uid
              document {
                ... on PrismicProject {
                  id
                  uid
                  data {
                    year
                    client
                    role
                    subtitle {
                      text
                    }

                    body1 {
                      ... on PrismicProjectDataBody1Image {
                        id
                        slice_type
                        primary {
                          column_span
                          pinned_side
                          image {
                            url
                            alt
                            gatsbyImageData(
                              layout: FULL_WIDTH
                              placeholder: NONE
                            )
                            dimensions {
                              height
                              width
                            }
                          }
                        }
                      }
                      ... on PrismicProjectDataBody1Carousel {
                        id
                        slice_type
                        primary {
                          column_span
                          pinned_side
                        }
                        items {
                          image {
                            alt
                            copyright
                            url
                            alt
                            gatsbyImageData(
                              layout: FULL_WIDTH
                              placeholder: NONE
                            )
                            dimensions {
                              height
                              width
                            }
                          }
                        }
                      }
                      ... on PrismicProjectDataBody1GridOfImages {
                        id
                        slice_type
                        primary {
                          column_span
                          pinned_side
                        }
                        items {
                          image {
                            alt
                            copyright
                            url
                            alt
                            gatsbyImageData(
                              layout: FULL_WIDTH
                              placeholder: NONE
                            )
                            dimensions {
                              height
                              width
                            }
                          }
                        }
                      }
                      ... on PrismicProjectDataBody1VideoEmbed {
                        id
                        slice_type
                        primary {
                          column_span
                          pinned_side
                          autoplay_video
                          does_video_have_audio
                          video {
                            thumbnail_url
                            width
                            height
                            embed_url
                          }
                        }
                      }
                      ... on PrismicProjectDataBody1Gif {
                        id
                        slice_type
                        primary {
                          column_span
                          pinned_side
                          gif {
                            url
                            alt
                            dimensions {
                              height
                              width
                            }
                          }
                        }
                      }
                    }

                    body2 {
                      ... on PrismicProjectDataBody2Image {
                        id
                        slice_type
                        primary {
                          image {
                            url
                            alt
                            gatsbyImageData(
                              layout: FULL_WIDTH
                              placeholder: NONE
                            )
                          }
                        }
                      }
                      ... on PrismicProjectDataBody2Video {
                        id
                        slice_type
                        primary {
                          video {
                            thumbnail_url
                            width
                            height
                            embed_url
                          }
                        }
                      }
                      ... on PrismicProjectDataBody2Gif {
                        id
                        slice_type
                        primary {
                          gif {
                            url
                            alt
                            dimensions {
                              width
                              height
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const TitleContainer = ({ year, client, role, subtitle }) => (
    <div className="title-container">
      <div className="column">
        <p className="title-container__year">{year}</p>
        <p>{subtitle}</p>

        <p className="mobile-client">{client}</p>
      </div>

      <p className="title-container__client">{client}</p>
      <p className="title-container__role">{role}</p>
    </div>
  );

  const scrollToProject = (id) => {
    if (typeof window !== "undefined") {
      setIsProjectNavigationOpen(false);

      window.scrollTo({
        behavior: "smooth",
        top:
          document.getElementById(`project-${id}`).getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          75,
      });
    }
  };

  const projects = data.prismicHomepage.data.projects
    .filter((project) => project.project !== null)
    .map((project, index) => {
      const doesBody2Exist = project.project.document.data.body2.length > 0;

      if (doesBody2Exist) {
        const body2Content = project.project.document.data.body2.map(
          (content, index) => {
            if (content.slice_type === "image" && index === 0) {
              return (
                <div className="content-container" key={content.id + index}>
                  <GatsbyImage
                    image={content.primary.image.gatsbyImageData}
                    alt={
                      content.primary.image.alt !== null
                        ? content.primary.image.alt
                        : ""
                    }
                  />
                </div>
              );
            } else if (content.slice_type === "gif" && index === 0) {
              return (
                <div className="content-container" key={content.id + index}>
                  <img src={content.primary.gif.url} loading="lazy" />
                </div>
              );
            } else if (content.slice_type === "video" && index === 0) {
              return (
                <div className="content-container" key={content.id + index}>
                  <Iframe
                    key={`${content.id}_${index}`}
                    video={content.primary.video.embed_url}
                    thumbnail={content.primary.video.thumbnail_url}
                    width={content.primary.video.width}
                    height={content.primary.video.height}
                    embed_url={content.primary.video.embed_url}
                    autoplay={true}
                    doesVideoHaveAudio={false}
                    setCurrentVideoPlayerId={setCurrentVideoPlayerId}
                    videoId={content.id}
                    isVideoPlaying={content.id === currentVideoPlayerId}
                  />
                </div>
              );
            } else {
              return null;
            }
          }
        );

        return (
          <li
            key={body2Content.id + index}
            className="list-item"
            onClick={() => scrollToProject(project.project.uid)}
          >
            <TitleContainer
              year={project.project.document.data.year}
              client={project.project.document.data.client}
              role={project.project.document.data.role}
              subtitle={project.project.document.data.subtitle.text}
            />
            {body2Content}
          </li>
        );
      } else {
        const body1Content = project.project.document.data.body1.map(
          (content, index) => {
            if (content.slice_type === "image" && index === 0) {
              return (
                <div className="content-container" key={content.id + index}>
                  <GatsbyImage
                    image={content.primary.image.gatsbyImageData}
                    alt={
                      content.primary.image.alt !== null
                        ? content.primary.image.alt
                        : ""
                    }
                  />
                </div>
              );
            } else if (content.slice_type === "gif" && index === 0) {
              return (
                <div className="content-container" key={content.id + index}>
                  <img src={content.primary.gif.url} loading="lazy" />
                </div>
              );
            } else if (content.slice_type === "video_embed" && index === 0) {
              return (
                <div className="content-container" key={content.id + index}>
                  <Iframe
                    key={`${content.id}_${index}`}
                    video={content.primary.video.embed_url}
                    thumbnail={content.primary.video.thumbnail_url}
                    width={content.primary.video.width}
                    height={content.primary.video.height}
                    embed_url={content.primary.video.embed_url}
                    autoplay={true}
                    doesVideoHaveAudio={false}
                    setCurrentVideoPlayerId={setCurrentVideoPlayerId}
                    videoId={content.id}
                    isVideoPlaying={content.id === currentVideoPlayerId}
                  />
                </div>
              );
            } else if (content.slice_type === "carousel" && index === 0) {
              const galleryImages = content.items.map((image, index) => {
                return (
                  <GatsbyImage
                    image={image.image.gatsbyImageData}
                    alt={image.image.alt !== null ? image.image.alt : ""}
                    key={content.id + index}
                  />
                );
              });
              return (
                <div className="content-container" key={content.id + index}>
                  <Gallery images={galleryImages} />
                </div>
              );
            } else if (content.slice_type === "grid_of_images" && index === 0) {
              const gridImages = content.items.map((image, index) => {
                return (
                  <GatsbyImage
                    image={image.image.gatsbyImageData}
                    alt={image.image.alt !== null ? image.image.alt : ""}
                    key={content.id + index}
                  />
                );
              });
              return (
                <div className="content-container" key={content.id + index}>
                  <GridOfImages key={content.id + index} images={gridImages} />
                </div>
              );
            } else {
              return null;
            }
          }
        );

        return (
          <li
            key={body1Content.id + index}
            className="list-item"
            onClick={() => scrollToProject(project.project.uid)}
          >
            <TitleContainer
              year={project.project.document.data.year}
              client={project.project.document.data.client}
              role={project.project.document.data.role}
              subtitle={project.project.document.data.subtitle.text}
            />
            {body1Content}
          </li>
        );
      }
    });

  return (
    <ProjectNavigationContainer>
      <ol>{projects}</ol>
    </ProjectNavigationContainer>
  );
};
