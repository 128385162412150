import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";

const GalleryContainer = styled.div`
  margin-bottom: 30px;
`;

const CarouselContainer = styled.div`
  & img {
    margin-bottom: 0;
  }
`;

const Gallery = ({ images }) => (
  <GalleryContainer>
    <CarouselContainer>
      <Carousel
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
      >
        {images}
      </Carousel>
    </CarouselContainer>
  </GalleryContainer>
);

export default Gallery;
